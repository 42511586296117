import i18n from 'localization/i18n';

export enum UNIT_TYPE {
  LENGTH = 'LENGTH',
  VOLUME = 'VOLUME',
  MASS = 'MASS',
  DURATION = 'DURATION',
  ENERGY = 'ENERGY',
  SCALAR = 'SCALAR',
  STEP = 'STEP',
}

export enum UNIT_SYMBOL {
  KM = 'kM',
  M = 'm',
  FT = 'ft',
  YD = 'yd',
  MI = 'mi',
  L = 'L',
  ML = 'mL',
  FL_OZ = 'fl oz',
  CUP = 'cup',
  KG = 'kg',
  G = 'g',
  MG = 'mg',
  MCG = 'mcg',
  OZ = 'oz',
  LB = 'lb',
  SEC = 'sec',
  MIN = 'min',
  HR = 'hr',
  J = 'J',
  KJ = 'kJ',
  KCAL = 'kCal',
  CAL = 'cal',
  REP = 'rep',
  STEP = 'step',
  NONE = '',
}

export enum UNIT_ALPHA {
  KM = 1000.0,
  M = 1.0,
  FT = 0.3048,
  YD = 0.9144,
  MI = 1609.34,
  L = 1.0,
  ML = 0.001,
  FL_OZ = 0.0295735,
  CUP = 0.24,
  KG = 1.0,
  G = 0.001,
  MG = 0.000001,
  MCG = 0.000000001,
  OZ = 0.0283495,
  LB = 0.453592,
  SEC = 1,
  MIN = 60,
  HR = 3600,
  J = 1.0,
  KJ = 1000.0,
  KCAL = 4184.0,
  CAL = 4.184,
  REP = 1,
  STEP = 1,
}

export interface ISIUnit {
  type: UNIT_TYPE;
  symbol: UNIT_SYMBOL;
  name: string;
  display: string;
  alpha: UNIT_ALPHA;
}

export interface ISIBaseUnit {
  [symbol: string]: ISIUnit;
}

export interface IUnitRange {
  max: number;
  min: number;
  step: number;
  length: number;
}

export interface IBaseUnitRange {
  [unit: string]: IUnitRange;
}

export const SIBaseUnit: ISIBaseUnit = {
  kM: {
    type: UNIT_TYPE.LENGTH,
    symbol: UNIT_SYMBOL.KM,
    name: 'Kilometers',
    display: 'km',
    alpha: UNIT_ALPHA.KM,
  },
  m: {
    type: UNIT_TYPE.LENGTH,
    symbol: UNIT_SYMBOL.M,
    name: 'Meters',
    display: 'm',
    alpha: UNIT_ALPHA.M,
  },
  ft: {
    type: UNIT_TYPE.LENGTH,
    symbol: UNIT_SYMBOL.FT,
    name: 'Feet',
    display: 'ft',
    alpha: UNIT_ALPHA.FT,
  },
  yd: {
    type: UNIT_TYPE.LENGTH,
    symbol: UNIT_SYMBOL.YD,
    name: 'Yards',
    display: 'yd',
    alpha: UNIT_ALPHA.YD,
  },
  mi: {
    type: UNIT_TYPE.LENGTH,
    symbol: UNIT_SYMBOL.MI,
    name: 'Miles',
    display: 'mi',
    alpha: UNIT_ALPHA.MI,
  },
  L: {
    type: UNIT_TYPE.VOLUME,
    symbol: UNIT_SYMBOL.L,
    name: 'Liters',
    display: 'l',
    alpha: UNIT_ALPHA.L,
  },
  mL: {
    type: UNIT_TYPE.VOLUME,
    symbol: UNIT_SYMBOL.ML,
    name: 'Milliliters',
    display: 'ml',
    alpha: UNIT_ALPHA.ML,
  },
  'fl oz': {
    type: UNIT_TYPE.VOLUME,
    symbol: UNIT_SYMBOL.FL_OZ,
    name: 'Fluid Ounces',
    display: 'US fl oz',
    alpha: UNIT_ALPHA.FL_OZ,
  },
  cup: {
    type: UNIT_TYPE.VOLUME,
    symbol: UNIT_SYMBOL.CUP,
    name: 'Cups',
    display: 'cup',
    alpha: UNIT_ALPHA.CUP,
  },
  kg: {
    type: UNIT_TYPE.MASS,
    symbol: UNIT_SYMBOL.KG,
    name: 'Kilograms',
    display: 'kg',
    alpha: UNIT_ALPHA.KG,
  },
  g: {
    type: UNIT_TYPE.MASS,
    symbol: UNIT_SYMBOL.G,
    name: 'Grams',
    display: 'g',
    alpha: UNIT_ALPHA.G,
  },
  mg: {
    type: UNIT_TYPE.MASS,
    symbol: UNIT_SYMBOL.MG,
    name: 'Milligrams',
    display: 'mg',
    alpha: UNIT_ALPHA.MG,
  },
  mcg: {
    type: UNIT_TYPE.MASS,
    symbol: UNIT_SYMBOL.MCG,
    name: 'Micrograms',
    display: 'µg',
    alpha: UNIT_ALPHA.MCG,
  },
  oz: {
    type: UNIT_TYPE.MASS,
    symbol: UNIT_SYMBOL.OZ,
    name: 'Ounces',
    display: 'oz',
    alpha: UNIT_ALPHA.OZ,
  },
  lb: {
    type: UNIT_TYPE.MASS,
    symbol: UNIT_SYMBOL.LB,
    name: 'Pounds',
    display: 'lb',
    alpha: UNIT_ALPHA.LB,
  },
  sec: {
    type: UNIT_TYPE.DURATION,
    symbol: UNIT_SYMBOL.SEC,
    name: 'Seconds',
    display: 'sec',
    alpha: UNIT_ALPHA.SEC,
  },
  min: {
    type: UNIT_TYPE.DURATION,
    symbol: UNIT_SYMBOL.MIN,
    name: 'Minutes',
    display: 'min',
    alpha: UNIT_ALPHA.MIN,
  },
  hr: {
    type: UNIT_TYPE.DURATION,
    symbol: UNIT_SYMBOL.HR,
    name: 'Hours',
    display: 'hr',
    alpha: UNIT_ALPHA.HR,
  },
  J: {
    type: UNIT_TYPE.ENERGY,
    symbol: UNIT_SYMBOL.J,
    name: 'Joules',
    display: 'J',
    alpha: UNIT_ALPHA.J,
  },
  kJ: {
    type: UNIT_TYPE.ENERGY,
    symbol: UNIT_SYMBOL.KJ,
    name: 'Kilojoules',
    display: 'kJ',
    alpha: UNIT_ALPHA.KJ,
  },
  kCal: {
    type: UNIT_TYPE.ENERGY,
    symbol: UNIT_SYMBOL.KCAL,
    name: 'Kilocalories',
    display: 'kCal',
    alpha: UNIT_ALPHA.KCAL,
  },
  cal: {
    type: UNIT_TYPE.ENERGY,
    symbol: UNIT_SYMBOL.CAL,
    name: 'Calories',
    display: 'cal',
    alpha: UNIT_ALPHA.CAL,
  },
  rep: {
    type: UNIT_TYPE.SCALAR,
    symbol: UNIT_SYMBOL.REP,
    name: 'Counts',
    display: i18n.t('measurement.unitTimes'),
    alpha: UNIT_ALPHA.REP,
  },
  step: {
    type: UNIT_TYPE.STEP,
    symbol: UNIT_SYMBOL.STEP,
    name: 'Steps',
    display: i18n.t('measurement.unitSteps'),
    alpha: UNIT_ALPHA.STEP,
  },
};

export const unitRange: IBaseUnitRange = {
  rep: {
    max: 100,
    min: 1,
    step: 1,
    length: 3,
  },
  step: {
    max: 100000,
    min: 1000,
    step: 1000,
    length: 6,
  },
  kM: {
    max: 50,
    min: 1,
    step: 1,
    length: 2,
  },
  m: {
    max: 50000,
    min: 10,
    step: 10,
    length: 5,
  },
  ft: {
    max: 100000,
    min: 100,
    step: 100,
    length: 6,
  },
  yd: {
    max: 50000,
    min: 50,
    step: 50,
    length: 5,
  },
  mi: {
    max: 30,
    min: 1,
    step: 1,
    length: 2,
  },
  L: {
    max: 30,
    min: 1,
    step: 1,
    length: 2,
  },
  mL: {
    max: 30000,
    min: 100,
    step: 100,
    length: 5,
  },
  'fl oz': {
    max: 1000,
    min: 5,
    step: 5,
    length: 4,
  },
  cup: {
    max: 100,
    min: 1,
    step: 1,
    length: 3,
  },
  kg: {
    max: 10,
    min: 1,
    step: 1,
    length: 2,
  },
  g: {
    max: 5000,
    min: 5,
    step: 5,
    length: 4,
  },
  mg: {
    max: 10000,
    min: 1,
    step: 1,
    length: 5,
  },
  mcg: {
    max: 10000,
    min: 100,
    step: 100,
    length: 5,
  },
  oz: {
    max: 350,
    min: 1,
    step: 1,
    length: 3,
  },
  lb: {
    max: 20,
    min: 1,
    step: 1,
    length: 2,
  },
  min: {
    max: 1200,
    min: 5,
    step: 5,
    length: 4,
  },
  hr: {
    max: 20,
    min: 1,
    step: 1,
    length: 2,
  },
  sec: {
    max: 72000,
    min: 10,
    step: 10,
    length: 5,
  },
  J: {
    max: 100000,
    min: 1000,
    step: 1000,
    length: 6,
  },
  kJ: {
    max: 42000,
    min: 50,
    step: 50,
    length: 5,
  },
  kCal: {
    max: 10000,
    min: 100,
    step: 100,
    length: 5,
  },
  cal: {
    max: 2000000,
    min: 500,
    step: 500,
    length: 7,
  },
};
