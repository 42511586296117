export type UnitType = 'length' | 'volume' | 'mass' | 'duration' | 'energy' | 'scalar' | 'step' | '';

export const unitSymbols = [
  'kM',
  'm',
  'ft',
  'yd',
  'mi',
  'L',
  'mL',
  'fl oz',
  'cup',
  'kg',
  'g',
  'mg',
  'mcg',
  'oz',
  'lb',
  'sec',
  'min',
  'hr',
  'J',
  'kJ',
  'kCal',
  'cal',
  'rep',
  'step',
  'steps',
  '',
] as const;

export type UnitSymbol = typeof unitSymbols[number];

export type SiUnit = {
  type: UnitType;
  symbol: UnitSymbol;
  name: string;
  display: string;
  alpha: number;
};