interface IColorModeValue {
  BACKGROUND: string;
  BACKGROUND_ALT?: string;
  TEXT_COLOR: string;
  SUB_TEXT_COLOR: string;
  BOX_BG_COLOR: string;
  BORDER_BOX_COLOR: string;
  INPUT_BG_COLOR: string;
  ACCENT_COLOR: string;
  FAILED_COLOR: string;
  TRACK_COLOR: string;
  TRACK_HIGHLIGHT_COLOR: string;
  ERROR_COLOR: string;
  BG_CARD_OVER_VIEW: string;
  COLOR_CARD_OVER_VIEW: string;
  COLOR_SUB_CARD_OVER_VIEW: string;
  COLOR_ICON_HABIT_AUTO: string;
  COLOR_MENU_LIST: string;
  BACKGROUND_CARD_HABIT_NAME_SINGLE: string;
  COLOR_TEXT_NOTES: string;
  BG_LOADER: string;
  COLOR_SECONDARY: string;
  APPLE_BACKGROUND: string;
  APPLE_COLOR: string;
  INPUT_COLOR: string;
  BACKGROUND_LEVEL_1: string;
  BACKGROUND_LEVEL_2: string;
  GROUPED_BACKGROUND_LEVEL_1: string;
  GROUPED_BACKGROUND_LEVEL_2: string;
  ELEVATED_LEVEL_1: string;
  ELEVATED_LEVEL_2: string;
  LABEL_PRIMARY: string;
  LABEL_SECONDARY: string;
  CONTROL_BACKGROUND: string;
  SEPARATOR: string;
  COMPLETED_COLOR: string;
  SKIP_COLOR: string;
  FAIL_COLOR: string;
  JAM_VIOLET: string;
  LIME_GREEN: string;
  GINGER_ORANGE: string;
  SKY_BLUE: string;
  MENU_BACKGROUND: string;
}

const lightMode: IColorModeValue = {
  BACKGROUND: '#FFFFFF',
  BACKGROUND_ALT: '#F5F5F5',
  TEXT_COLOR: '#000000',
  SUB_TEXT_COLOR: '#7F7F90',
  BOX_BG_COLOR: '#FFFFFF',
  BORDER_BOX_COLOR: '#E5E5E5',
  INPUT_BG_COLOR: '#F5F5F5',
  ACCENT_COLOR: '#2A67F4',
  FAILED_COLOR: '#000000',
  TRACK_COLOR: '#EDEDED',
  TRACK_HIGHLIGHT_COLOR: '#FF7017',
  ERROR_COLOR: '#D23737',
  BG_CARD_OVER_VIEW: '#f5f5f5',
  COLOR_CARD_OVER_VIEW: '#7B7C7C',
  COLOR_SUB_CARD_OVER_VIEW: '#7F7F7F',
  COLOR_ICON_HABIT_AUTO: '#7b7c7c',
  COLOR_MENU_LIST: '#fff',
  BACKGROUND_CARD_HABIT_NAME_SINGLE: '#fff',
  COLOR_TEXT_NOTES: '#0000004d',
  BG_LOADER: '#ffffff',
  COLOR_SECONDARY: '#7f7f7f',
  APPLE_BACKGROUND: '#000000',
  APPLE_COLOR: '#ffffff',
  INPUT_COLOR: '#f5f5f5',
  BACKGROUND_LEVEL_1: '#FFFFFF',
  BACKGROUND_LEVEL_2: '#F5F5F5',
  GROUPED_BACKGROUND_LEVEL_1: '#FFFFFF',
  GROUPED_BACKGROUND_LEVEL_2: '#FFFFFF',
  ELEVATED_LEVEL_1: '#FFFFFF',
  ELEVATED_LEVEL_2: '#F5F5F5',
  SEPARATOR: '#E5E4E5',
  LABEL_PRIMARY: '#000000',
  LABEL_SECONDARY: '#7B7C7C',
  COMPLETED_COLOR: '#2A67F4',
  SKIP_COLOR: '#BCCAED',
  FAIL_COLOR: '#000000',
  JAM_VIOLET: '#9B3AEE',
  LIME_GREEN: '#56AD59',
  GINGER_ORANGE: '#F3AE46',
  SKY_BLUE: '#4C74C9',
  CONTROL_BACKGROUND: '#FFFFFF',
  MENU_BACKGROUND: '#FCFCFC',
};

const darkMode: IColorModeValue = {
  BACKGROUND: '#000000',
  BACKGROUND_ALT: '#151515',
  TEXT_COLOR: '#FFFFFF',
  SUB_TEXT_COLOR: '#7F7F73',
  BOX_BG_COLOR: '#313131',
  BORDER_BOX_COLOR: '#3B3B3B',
  INPUT_BG_COLOR: '#262626',
  ACCENT_COLOR: '#477EFF',
  FAILED_COLOR: '#313131',
  TRACK_COLOR: '#7F7F7F',
  TRACK_HIGHLIGHT_COLOR: '#FF7017',
  ERROR_COLOR: '#D23737',
  BG_CARD_OVER_VIEW: '#313131',
  COLOR_CARD_OVER_VIEW: '#7F7F7F',
  COLOR_SUB_CARD_OVER_VIEW: '#7F7F7F',
  COLOR_ICON_HABIT_AUTO: '#FFFFFF',
  COLOR_MENU_LIST: '#1e1e1e',
  BACKGROUND_CARD_HABIT_NAME_SINGLE: '#262626',
  COLOR_TEXT_NOTES: '#ffffff99',
  BG_LOADER: '#262626',
  COLOR_SECONDARY: '#7f7f7f',
  APPLE_BACKGROUND: '#e8e8e8 ',
  APPLE_COLOR: '#000000 ',
  INPUT_COLOR: '#000000',
  BACKGROUND_LEVEL_1: '#171717',
  BACKGROUND_LEVEL_2: '#323232',
  GROUPED_BACKGROUND_LEVEL_1: '#171717',
  GROUPED_BACKGROUND_LEVEL_2: '#323232',
  ELEVATED_LEVEL_1: '#323232',
  ELEVATED_LEVEL_2: '#414141',
  LABEL_PRIMARY: '#FFFFFFF2',
  LABEL_SECONDARY: '#7B7C7C',
  SEPARATOR: '#292829',
  COMPLETED_COLOR: '#3662CC',
  SKIP_COLOR: '#A1ADCB',
  FAIL_COLOR: '#C1C1C1',
  JAM_VIOLET: '#7631B1',
  LIME_GREEN: '#517B52',
  GINGER_ORANGE: '#DB9D40',
  SKY_BLUE: '#3C5FAA',
  CONTROL_BACKGROUND: '#424242',
  MENU_BACKGROUND: '#333333',
};

export { lightMode, darkMode };
