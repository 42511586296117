type FontFamilyProperty = 'Inter';
type FontSizeProperty = 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 20 | 22 | 26;
type FontWeightProperty = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

const getLineHeightProperty = (fontSize: FontSizeProperty): string => `${fontSize * 1.2}px`;

type TypographyProperty = {
  fontFamily: FontFamilyProperty;
  fontSize: FontSizeProperty;
  fontWeight: FontWeightProperty;
  lineHeight: string;
};

type TypographyStyle = {
  title: {
    large: TypographyProperty;
    1: TypographyProperty;
    2: TypographyProperty;
    3: TypographyProperty;
    4: TypographyProperty;
    5: TypographyProperty;
  };
  headline: TypographyProperty;
  subHeadline: TypographyProperty;
  body: TypographyProperty;
  callout: TypographyProperty;
  footnote: TypographyProperty;
  caption: {
    1: TypographyProperty;
    2: TypographyProperty;
  };
};

type OtherTypographyStyle = {
  heading: {
    4: TypographyProperty;
    5: TypographyProperty;
  },
  title: {
    3: TypographyProperty;
    4: TypographyProperty;
    5: TypographyProperty;
    6: TypographyProperty
  };
  caption: {
    2: TypographyProperty;
  };
};

export interface Typography {
  normal: TypographyStyle;
  emphasized: TypographyStyle;
  other: OtherTypographyStyle
}

export const typography: Typography = {
  normal: {
    title: {
      large: {
        fontFamily: 'Inter',
        fontSize: 26,
        fontWeight: 500,
        lineHeight: getLineHeightProperty(26),
      },
      1: {
        fontFamily: 'Inter',
        fontSize: 22,
        fontWeight: 500,
        lineHeight: getLineHeightProperty(22),
      },
      2: {
        fontFamily: 'Inter',
        fontSize: 17,
        fontWeight: 500,
        lineHeight: getLineHeightProperty(17),
      },
      3: {
        fontFamily: 'Inter',
        fontSize: 15,
        fontWeight: 500,
        lineHeight: getLineHeightProperty(15),
      },
      4: {
        fontFamily: 'Inter',
        fontSize: 13,
        fontWeight: 500,
        lineHeight: getLineHeightProperty(13),
      },
      5: {
        fontFamily: 'Inter',
        fontSize: 18,
        fontWeight: 500,
        lineHeight: getLineHeightProperty(18),
      },
    },
    headline: {
      fontFamily: 'Inter',
      fontSize: 13,
      fontWeight: 600,
      lineHeight: getLineHeightProperty(13),
    },
    subHeadline: {
      fontFamily: 'Inter',
      fontSize: 11,
      fontWeight: 500,
      lineHeight: getLineHeightProperty(11),
    },
    body: {
      fontFamily: 'Inter',
      fontSize: 13,
      fontWeight: 400,
      lineHeight: getLineHeightProperty(13),
    },
    callout: {
      fontFamily: 'Inter',
      fontSize: 12,
      fontWeight: 400,
      lineHeight: getLineHeightProperty(12),
    },
    footnote: {
      fontFamily: 'Inter',
      fontSize: 10,
      fontWeight: 400,
      lineHeight: getLineHeightProperty(10),
    },
    caption: {
      1: {
        fontFamily: 'Inter',
        fontSize: 10,
        fontWeight: 400,
        lineHeight: getLineHeightProperty(10),
      },
      2: {
        fontFamily: 'Inter',
        fontSize: 10,
        fontWeight: 500,
        lineHeight: getLineHeightProperty(10),
      },
    },
  },
  emphasized: {
    title: {
      large: {
        fontFamily: 'Inter',
        fontSize: 26,
        fontWeight: 700,
        lineHeight: getLineHeightProperty(26),
      },
      1: {
        fontFamily: 'Inter',
        fontSize: 22,
        fontWeight: 700,
        lineHeight: getLineHeightProperty(22),
      },
      2: {
        fontFamily: 'Inter',
        fontSize: 17,
        fontWeight: 600,
        lineHeight: getLineHeightProperty(17),
      },
      3: {
        fontFamily: 'Inter',
        fontSize: 15,
        fontWeight: 600,
        lineHeight: getLineHeightProperty(15),
      },
      4: {
        fontFamily: 'Inter',
        fontSize: 13,
        fontWeight: 600,
        lineHeight: getLineHeightProperty(13),
      },
      5: {
        fontFamily: 'Inter',
        fontSize: 18,
        fontWeight: 600,
        lineHeight: getLineHeightProperty(18),
      }
    },
    headline: {
      fontFamily: 'Inter',
      fontSize: 13,
      fontWeight: 600,
      lineHeight: getLineHeightProperty(13),
    },
    subHeadline: {
      fontFamily: 'Inter',
      fontSize: 11,
      fontWeight: 600,
      lineHeight: getLineHeightProperty(11),
    },
    body: {
      fontFamily: 'Inter',
      fontSize: 13,
      fontWeight: 500,
      lineHeight: getLineHeightProperty(13),
    },
    callout: {
      fontFamily: 'Inter',
      fontSize: 12,
      fontWeight: 500,
      lineHeight: getLineHeightProperty(12),
    },
    footnote: {
      fontFamily: 'Inter',
      fontSize: 10,
      fontWeight: 600,
      lineHeight: getLineHeightProperty(10),
    },
    caption: {
      1: {
        fontFamily: 'Inter',
        fontSize: 10,
        fontWeight: 500,
        lineHeight: getLineHeightProperty(10),
      },
      2: {
        fontFamily: 'Inter',
        fontSize: 10,
        fontWeight: 600,
        lineHeight: getLineHeightProperty(10),
      },
    },
  },
  other: {
    title: {
      3: {
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 500,
        lineHeight: getLineHeightProperty(16),
      },
      4: {
        fontFamily: 'Inter',
        fontSize: 14,
        fontWeight: 500,
        lineHeight: getLineHeightProperty(14),
      },
      5: {
        fontFamily: 'Inter',
        fontSize: 13,
        fontWeight: 500,
        lineHeight: getLineHeightProperty(13),
      },
      6: {
        fontFamily: 'Inter',
        fontSize: 9,
        fontWeight: 500,
        lineHeight: getLineHeightProperty(11),
      }
    },
    heading: {
      4: {
        fontFamily: 'Inter',
        fontSize: 20,
        fontWeight: 600,
        lineHeight: getLineHeightProperty(20),
      },
      5: {
        fontFamily: 'Inter',
        fontSize: 18,
        fontWeight: 600,
        lineHeight: getLineHeightProperty(18),
      }
    },
    caption: {
      2: {
        fontFamily: 'Inter',
        fontSize: 11,
        fontWeight: 600,
        lineHeight: getLineHeightProperty(11),
      }
    },
  }
};
