import { SiUnit, UnitSymbol, unitSymbols, UnitType } from 'models/common';
import memoizeOne from 'memoize-one';

export const SIUnits: SiUnit[] = [
  {
    type: 'length',
    symbol: 'kM',
    name: 'Kilometers',
    display: 'km',
    alpha: 1000.0,
  },
  {
    type: 'length',
    symbol: 'm',
    name: 'Meters',
    display: 'm',
    alpha: 1.0,
  },
  {
    type: 'length',
    symbol: 'ft',
    name: 'Feet',
    display: 'ft',
    alpha: 0.3048,
  },
  {
    type: 'length',
    symbol: 'yd',
    name: 'Yards',
    display: 'yd',
    alpha: 0.9144,
  },
  {
    type: 'length',
    symbol: 'mi',
    name: 'Miles',
    display: 'mi',
    alpha: 1609.34,
  },
  {
    type: 'volume',
    symbol: 'L',
    name: 'Liters',
    display: 'l',
    alpha: 1.0,
  },
  {
    type: 'volume',
    symbol: 'mL',
    name: 'Milliliters',
    display: 'ml',
    alpha: 0.001,
  },
  {
    type: 'volume',
    symbol: 'fl oz',
    name: 'Fluid Ounces',
    display: 'US fl oz',
    alpha: 0.0295735,
  },
  {
    type: 'volume',
    symbol: 'cup',
    name: 'Cups',
    display: 'cup',
    alpha: 0.24,
  },
  {
    type: 'mass',
    symbol: 'kg',
    name: 'Kilograms',
    display: 'kg',
    alpha: 1.0,
  },
  {
    type: 'mass',
    symbol: 'g',
    name: 'Grams',
    display: 'g',
    alpha: 0.001,
  },
  {
    type: 'mass',
    symbol: 'mg',
    name: 'Milligrams',
    display: 'mg',
    alpha: 0.000001,
  },
  {
    type: 'mass',
    symbol: 'mcg',
    name: 'Micrograms',
    display: 'µg',
    alpha: 0.000000001,
  },
  {
    type: 'mass',
    symbol: 'oz',
    name: 'Ounces',
    display: 'oz',
    alpha: 0.0283495,
  },
  {
    type: 'mass',
    symbol: 'lb',
    name: 'Pounds',
    display: 'lb',
    alpha: 0.453592,
  },
  {
    type: 'duration',
    symbol: 'sec',
    name: 'Seconds',
    display: 'sec',
    alpha: 1,
  },
  {
    type: 'duration',
    symbol: 'min',
    name: 'Minutes',
    display: 'min',
    alpha: 60,
  },
  {
    type: 'duration',
    symbol: 'hr',
    name: 'Hours',
    display: 'hr',
    alpha: 3600,
  },
  {
    type: 'energy',
    symbol: 'J',
    name: 'Joules',
    display: 'J',
    alpha: 1.0,
  },
  {
    type: 'energy',
    symbol: 'kJ',
    name: 'Kilojoules',
    display: 'kJ',
    alpha: 1000.0,
  },
  {
    type: 'energy',
    symbol: 'kCal',
    name: 'Kilocalories',
    display: 'kCal',
    alpha: 4184.0,
  },
  {
    type: 'energy',
    symbol: 'cal',
    name: 'Calories',
    display: 'cal',
    alpha: 4.184,
  },
  {
    type: 'scalar',
    symbol: 'rep',
    name: 'Counts',
    display: 'times',
    alpha: 1,
  },
  {
    type: 'step',
    symbol: 'step',
    name: 'Steps',
    display: 'steps',
    alpha: 1,
  },
];

const isSIUnitSymbol = memoizeOne((rawUnitSymbol: any): rawUnitSymbol is UnitSymbol => {
  return unitSymbols.includes(rawUnitSymbol);
});

export const getSIUnitSymbol = memoizeOne(({ rawUnitSymbol }: { rawUnitSymbol: any }): UnitSymbol => {
  let unitSymbol: UnitSymbol = '';
  if (isSIUnitSymbol(rawUnitSymbol)) {
    unitSymbol = rawUnitSymbol;
  }
  return unitSymbol;
});

export const getType = memoizeOne(({ unitSymbol }: { unitSymbol: UnitSymbol }): UnitType => {
  const unit = SIUnits.find((unit) => unit.symbol === unitSymbol);
  if (unit) {
    return unit.type;
  }
  return '';
});

export const getBaseUnitFromType = memoizeOne(({ type }: { type: UnitType }): UnitSymbol => {
  switch (type) {
    case 'length':
      return 'm';
    case 'volume':
      return 'L';
    case 'duration':
      return 'sec';
    case 'mass':
      return 'kg';
    case 'energy':
      return 'J';
    case 'scalar':
      return 'rep';
    case 'step':
      return 'step';
    default:
      return '';
  }
});

export const convert = memoizeOne(
  ({ source, target, value }: { source: UnitSymbol; target: UnitSymbol; value: number }): number => {
    const sourceUnit = SIUnits.find((unit) => unit.symbol === source);
    const targetUnit = SIUnits.find((unit) => unit.symbol === target);

    if (!sourceUnit) throw new Error(`Cannot found unit with symbol ${source}`);
    if (!targetUnit) throw new Error(`Cannot found unit with symbol ${target}`);

    if (!sourceUnit && !targetUnit) throw new Error(`Cannot found unit with symbol ${source} and ${target}`);

    if (sourceUnit.type !== targetUnit.type) {
      throw new Error(`Cannot convert from ${sourceUnit.type} to ${targetUnit.type}`);
    }

    const sourceAlpha = sourceUnit.alpha;
    const targetAlpha = targetUnit.alpha;
    return (sourceAlpha / targetAlpha) * value;
  },
);
