import { useColorMode } from '@chakra-ui/system';
import { ReactNode, FC, ReactElement } from 'react';
import { darkColorScheme, lightColorScheme, typography } from './data';
import ThemeContext from './theme.context';

interface Props {
  children?: ReactNode | undefined;
}

const ThemeProvider: FC<Props> = ({ children }: Props): ReactElement => {
  const { colorMode } = useColorMode();
  return (
    <ThemeContext.Provider
      value={{
        mode: colorMode,
        typography: typography,
        colorScheme: colorMode === 'light' ? lightColorScheme : darkColorScheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
