import ca from './locales/ca.json';
import cs from './locales/cs.json';
import da from './locales/da.json';
import de from './locales/de.json';
import en from './locales/en.json';
import es from './locales/es.json';
import fi from './locales/fi.json';
import fr from './locales/fr.json';
import hi from './locales/hi.json';
import hu from './locales/hu.json';
import it from './locales/it.json';
import ja from './locales/ja.json';
import ko from './locales/ko.json';
import nl from './locales/nl.json';
import pl from './locales/pl.json';
import pt from './locales/pt.json';
import ro from './locales/ro.json';
import ru from './locales/ru.json';
import sk from './locales/sk.json';
import sv from './locales/sv.json';
import tr from './locales/tr.json';
import uk from './locales/uk.json';
import vi from './locales/vi.json';
import zhHans from './locales/zh-Hans.json';
import zhHant from './locales/zh-Hant.json';

import caPlurals from './locales/plurals/ca.json';
import csPlurals from './locales/plurals/cs.json';
import daPlurals from './locales/plurals/da.json';
import dePlurals from './locales/plurals/de.json';
import enPlurals from './locales/plurals/en.json';
import esPlurals from './locales/plurals/es.json';
import fiPlurals from './locales/plurals/fi.json';
import frPlurals from './locales/plurals/fr.json';
import hiPlurals from './locales/plurals/hi.json';
import huPlurals from './locales/plurals/hu.json';
import itPlurals from './locales/plurals/it.json';
import jaPlurals from './locales/plurals/ja.json';
import koPlurals from './locales/plurals/ko.json';
import nlPlurals from './locales/plurals/nl.json';
import plPlurals from './locales/plurals/pl.json';
import ptPlurals from './locales/plurals/pt.json';
import roPlurals from './locales/plurals/ro.json';
import ruPlurals from './locales/plurals/ru.json';
import skPlurals from './locales/plurals/sk.json';
import svPlurals from './locales/plurals/sv.json';
import trPlurals from './locales/plurals/tr.json';
import ukPlurals from './locales/plurals/uk.json';
import viPlurals from './locales/plurals/vi.json';
import zhHansPlurals from './locales/plurals/zh-Hans.json';
import zhHantPlurals from './locales/plurals/zh-Hant.json';

export const resources = {
  ca: {
    translation: Object.assign(ca, caPlurals),
  },
  cs: {
    translation: Object.assign(cs, csPlurals),
  },
  da: {
    translation: Object.assign(da, daPlurals),
  },
  de: {
    translation: Object.assign(de, dePlurals),
  },
  en: {
    translation: Object.assign(en, enPlurals),
  },
  es: {
    translation: Object.assign(es, esPlurals),
  },
  fi: {
    translation: Object.assign(fi, fiPlurals),
  },
  fr: {
    translation: Object.assign(fr, frPlurals),
  },
  hi: {
    translation: Object.assign(hi, hiPlurals),
  },
  hu: {
    translation: Object.assign(hu, huPlurals),
  },
  it: {
    translation: Object.assign(it, itPlurals),
  },
  ja: {
    translation: Object.assign(ja, jaPlurals),
  },
  ko: {
    translation: Object.assign(ko, koPlurals),
  },
  nl: {
    translation: Object.assign(nl, nlPlurals),
  },
  pl: {
    translation: Object.assign(pl, plPlurals),
  },
  pt: {
    translation: Object.assign(pt, ptPlurals),
  },
  ro: {
    translation: Object.assign(ro, roPlurals),
  },
  ru: {
    translation: Object.assign(ru, ruPlurals),
  },
  sk: {
    translation: Object.assign(sk, skPlurals),
  },
  sv: {
    translation: Object.assign(sv, svPlurals),
  },
  tr: {
    translation: Object.assign(tr, trPlurals),
  },
  uk: {
    translation: Object.assign(uk, ukPlurals),
  },
  vi: {
    translation: Object.assign(vi, viPlurals),
  },
  'zh-Hans': {
    translation: Object.assign(zhHans, zhHansPlurals),
  },
  'zh-Hant': {
    translation: Object.assign(zhHant, zhHantPlurals),
  },
  'zh-CN': {
    translation: Object.assign(zhHans, zhHansPlurals),
  },
  zh: {
    translation: Object.assign(zhHans, zhHansPlurals),
  },
  'zh-TW': {
    translation: Object.assign(zhHant, zhHantPlurals),
  },
};
