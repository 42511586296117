import { UnitSymbol } from '../common';

export const goalPeriodicity = ['daily', 'weekly', 'monthly', ''] as const;

export type GoalPeriodicity = typeof goalPeriodicity[number];

export type GoalUnit = {
  symbol?: UnitSymbol | null;
  type?: string | null;
};

export type HabitGoal = {
  createdAt?: string | null;
  periodicity?: GoalPeriodicity | null;
  unit?: GoalUnit | null;
  value?: number | null;
};

export type HabitGoals = {
  [id: string]: HabitGoal;
};
