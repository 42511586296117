import { PopoverContent } from "@chakra-ui/react";
import { useThemeData } from "hooks/useThemeData";
import React, { ReactElement, ReactNode } from "react";
interface Props {
  children?: ReactNode;
  mRef?: React.Ref<HTMLDivElement>;
  style?: React.CSSProperties | undefined;
}

const MenuListLayout: React.FC<Props> = ({
  children,
  mRef,
  style,
}: Props): ReactElement => {
  const { colorScheme } = useThemeData();
  return (
    <PopoverContent
      ref={mRef}
      zIndex="99"
      borderRadius="5px"
      boxShadow="0px 0px 6px rgba(0, 0, 0, 0.08)"
      bgColor={colorScheme.platform.menu}
      border={`1px solid ${colorScheme.other.border}`}
      padding="0"
      style={style}
      _focus={{ outline: "none" }}
    >
      {children}
    </PopoverContent>
  );
};
export default React.memo(MenuListLayout);
