import { resources } from './resources';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import Backend from 'i18next-http-backend';

const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng',
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    // Change this to 'en' if default language should be English
    detection: options,
    resources,
    // lng: 'en',
    fallbackLng: 'en',
    react: {
      useSuspense: false,
    },
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
