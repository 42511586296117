import { ColorMode } from '@chakra-ui/react';
import React from 'react';
import { ColorScheme, lightColorScheme, typography, Typography } from './data';

export type ThemeContextProperty = {
  mode: ColorMode;
  typography: Typography;
  colorScheme: ColorScheme;
};

const ThemeContext = React.createContext<ThemeContextProperty>({
  mode: 'light',
  typography: typography,
  colorScheme: lightColorScheme,
});

export default ThemeContext;
